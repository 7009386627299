
















import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import Day from '@/modules/common/types/day.type';
import HotelRatesDayPopup from './rates-day-popup.vue';
import RatesAnalysisPreviewTooltip from './analysis/rates-analysis-preivew-tooltip.vue';
import { HotelTableData } from '../interfaces/hotel-popup-table-data.interface';

@Component({
    components: {
        HotelRatesDayPopup,
        RatesAnalysisPreviewTooltip,
    },
})
export default class RatesPopup extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;

    public analysisTooltipFocusElement: HTMLElement | null = null;
    public analysisRowData: HotelTableData | null = null;

    onTriggerScan(day: Day) {
        this.ratesService.triggerScanNew(day);
    }

    setAnalysisTooltipData(focusElement: HTMLElement, data: HotelTableData) {
        this.analysisTooltipFocusElement = focusElement;
        this.analysisRowData = data;
    }

    resetAnalysisTooltipData() {
        this.analysisTooltipFocusElement = null;
    }

    get isAnalysisMode() {
        if (!this.$route.name) return false;

        return this.$route.name.includes('analysis');
    }
}

