
/* eslint-disable no-param-reassign */
import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesPriceHistoryCommonService, {
    RatesPriceHistoryCommonServiceS,
} from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';

import Intraday from '@/modules/common/components/intraday.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import RatesDayPopup, { ColumnData } from '@/modules/common/modules/rates/components/common-popup/rates-day-popup.vue';
import ClipText from '@/modules/common/filters/clip-text.filter';

import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import PRICE_SHOWN from '../constants/price-shown.constant';

import RatesDocumentItemModel from '../models/rates-document-item.model';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../rates-analysis-filters.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../rates-filters.service';
import { HotelTableData } from '../interfaces/hotel-popup-table-data.interface';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    extends: RatesDayPopup,
    components: {
        ProviderCard,
    },
})
export default class HotelRatesDayPopup extends RatesDayPopup<HotelTableData> {
    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Inject(RatesPriceHistoryCommonServiceS)
    private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    @Inject(RatesFiltersServiceS)
    private ratesFiltersService!: RatesFiltersService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    public baseClass = 'hotel-day-popup';

    get modalWidth() {
        const isLarge = this.isIntraday
            || this.isAnalysisMode
            || this.isCheapestMode;

        return isLarge
            ? 1200
            : 1000;
    }

    get isPriceHistoryAvailable() {
        return !this.isCheapestMode;
    }

    get isIntraday() {
        return this.ratesService
            .isIntraday(this.day);
    }

    get isCheapestMode() {
        const { comparisonKey } = this.ratesAnalysisFiltersService;
        const { provider: mainProvider } = this.ratesService.settings;

        if (this.isAnalysisMode && comparisonKey === 'provider') {
            return this.ratesAnalysisFiltersService
                .comparisonValues[0]
                .value === 'cheapest' || mainProvider === 'cheapest';
        }

        return this.ratesService.settings.provider === 'cheapest';
    }

    get isPriceToggleActive() {
        return !this.ratesService.isAllChannelsMode;
    }

    get isAllChannelsMode() {
        return this.ratesService.isAllChannelsMode;
    }

    get isTableDataAvailable() {
        return this.isLoading
            || !this.isNoData
            || !!this.tableData.length;
    }

    get isNoData() {
        return this.ratesService.isNoData(this.day);
    }

    get isScanAvailable() {
        return this.ratesService.isScanAvailable(this.day);
    }

    get isOutOfRange() {
        return !this.ratesService.data;
    }

    get isLoading() {
        return this.ratesService.isLoading;
    }

    get hotelId() {
        const { currentHotelId } = this.userService;

        return +this.$route.params.hoteldId! || currentHotelId!;
    }

    get doc() {
        return this.ratesService.data;
    }

    get occupancy() {
        return this.ratesService.getOccupancy(this.day);
    }

    get demand() {
        return this.ratesService.getDemand(this.day);
    }

    get textAboveMetrics() {
        if (this.isAnalysisMode) {
            const { filterComparisonName, comparisonValues } = this.ratesAnalysisFiltersService;
            const valueLabel = comparisonValues[0].name;

            return `Comparison to: ${filterComparisonName} - ${valueLabel}`;
        }

        return '';
    }

    get competitionPercent() {
        const percent = this.isAnalysisMode
            ? this.ratesAnalysisService.getRoomDifference(this.day, this.hotelId)
            : this.ratesService.getCompetitionPercent(this.day);

        if (percent === null) return 0;

        return Math.round(percent * 100);
    }

    get averagePrice() {
        return this.isAllChannelsMode
            ? this.ratesService.getAverageRoomsPrice(this.day)
            : 0;
    }

    get lowestPrice() {
        return !this.compsetDataType.isNoData && !this.compsetDataType.isSoldOut
            ? this.ratesService.getLowestPrice(this.day)
            : 0;
    }

    get highestPrice() {
        return !this.compsetDataType.isNoData && !this.compsetDataType.isSoldOut
            ? this.ratesService.getHighestPrice(this.day)
            : 0;
    }

    set priceShown(value: PRICE_SHOWN) {
        this.ratesFiltersService.setPriceShown(value);
    }

    get priceShown() {
        return this.ratesFiltersService.settings.priceShown;
    }

    get dayColor() {
        let assessment = this.isAnalysisMode
            ? this.ratesAnalysisService.getCardAssessment(this.day)
            : this.ratesService.getCardAssessment(this.day);

        if (this.isAllChannelsMode) {
            const { currentCompset } = this.compsetsService;
            const diff = this.highestPrice
                ? (this.averagePrice - this.highestPrice) / this.highestPrice
                : null;

            assessment = this.ratesCommonService
                .getCardAssessment(diff!, currentCompset!);
        }

        let color = 'grey';

        switch (assessment) {
            case ASSESSMENT_TYPES.GOOD:
                color = 'green';
                break;
            case ASSESSMENT_TYPES.NORMAL:
                color = 'yellow';
                break;
            case ASSESSMENT_TYPES.BAD:
                color = 'red';
                break;
            default: break;
        }

        if (this.compsetDataType.isNA) {
            color = 'red';
        }

        return { [color]: true };
    }

    get compsetDataType() {
        const { day, hotelId } = this;

        const isNoAverage = !this.averagePrice;

        return {
            isNA: this.isAllChannelsMode
                ? false
                : this.ratesService.isNA(day, hotelId!),
            isSoldOut: this.isAllChannelsMode
                ? isNoAverage
                : this.ratesService.isSoldOut(day, hotelId!),
            isNoData: this.ratesService.isNoData(day),
        };
    }

    get compsetType() {
        const { currentCompset } = this.compsetsService;

        return currentCompset
            ? currentCompset.type
            : COMPSET_TYPE.MEDIAN;
    }

    get scanButtonProps() {
        const lastScan = this.ratesService.getUpdateDate(this.day);
        const { day } = this;

        return {
            lastScan,
            day,
            data: this.ratesService.data,
            settings: this.ratesService.settings,
            showScanDate: true,
        };
    }

    get hotelNames(): { [k: number]: string } {
        const { data: document } = this.ratesService;
        const { currentHotelId } = this.userService;
        const { competitors } = this.compsetsService;

        if (!document) {
            return {};
        }

        let competitorNames = {};

        if (competitors) {
            const entries = [...competitors, currentHotelId!]
                .map(hotelId => [
                    hotelId,
                    this.ratesService.getHotelName(hotelId) || this.hotelsService.getHotelName(hotelId),
                ]);
            competitorNames = Object.fromEntries(entries);
        }

        return {
            ...document.hotelNames,
            ...competitorNames,
        };
    }

    get isIntradayLoading() {
        return this.ratesService.isIntradayLoading;
    }

    get columns(): ColumnData<HotelTableData>[] {
        const { priceType } = this.ratesFiltersService.settings;

        const priceCol = {
            label: 'Price',
            field: 'price',
        } as ColumnData<HotelTableData>;

        const cols = [
            {
                label: '',
                field: 'isBasic',
                component: isBasic => ({
                    is: isBasic ? 'img' : 'span',
                    src: BOOKING_BASIC_ICON,
                }),
            },
            priceCol,
            {
                label: '',
                field: 'losRestriction',
                component: losRestriction => ({
                    is: 'i',
                    class: !losRestriction ? '' : 'icon-los2',
                    'data-los': losRestriction,
                }),
            },
            {
                label: 'Rank',
                field: 'rank',
                width: '50px',
            },
            {
                label: 'Hotel Name',
                field: 'hotelName',
                component: (value, item) => ({
                    is: item.link ? 'a' : 'span',
                    target: '_blank',
                    href: item.link,
                    text: this.cutString(value as string),
                    title: value,
                    class: item.link ? 'link' : '',
                }),
            },
            {
                label: 'Room Name',
                field: 'roomName',
                component: value => ({
                    is: 'span',
                    title: value,
                    text: this.cutString(String(value)),
                }),
            },
        ] as ColumnData<HotelTableData>[];

        if (priceType === PRICE_TYPE.LOWEST) {
            cols.splice(0, 0, {
                width: '80px',
                label: 'Type',
                field: 'priceType',
            });
        }

        if (this.isIntraday) {
            cols.splice(1, 0, {
                label: 'Previous scan',
                field: 'intradayPrice',
                component: (value, item) => ({
                    is: value !== 'Sold out' && !item.isCompset
                        ? (Intraday as unknown as Vue)
                        : 'div',
                    text: this.isIntradayLoading && !item.isCompset
                        ? '...'
                        : value,
                    textAsSlot: true,
                }),
            });
        }

        if (this.isAnalysisMode) {
            this.defineColumnsForAnalysis(cols, priceCol);
        }

        if (this.ratesService.isAllChannelsMode) {
            cols.splice(1, 1);
            cols.pop();
            cols.push({
                label: 'Diff',
                field: 'diff',
            });
        }

        if (this.isCheapestMode) {
            cols.push({
                label: 'Source',
                field: 'provider',
                noText: true,
                component: provider => ({
                    is: 'ProviderCard',
                    provider: provider || '',
                    hasLogo: true,
                }),
            });
        }

        return cols;
    }

    get rawData() {
        return this.ratesService.data;
    }

    get tableData(): HotelTableData[] {
        if (this.isLoading) return this.getDummyData();
        if (this.isNoData) return [];

        const { day, hotelId } = this;
        const { ratesService } = this;

        const rooms = ratesService.getCompetitorsRooms(day);
        const myRoom = ratesService.isAllChannelsMode
            ? null
            : ratesService.getRoom(day, hotelId);

        if (!this.ratesService.isAllChannelsMode) {
            rooms[hotelId] = myRoom;
        }

        const compsetRoom = this.getCompsetRoom();
        const rawEntries = Object.entries(rooms) as unknown as [number | string, RatesDocumentItemModel][];

        const byPriceDescending = (
            a: HotelTableData,
            b: HotelTableData,
        ) => +b.price - +a.price;

        const addRank = (room: HotelTableData, i: number) => {
            room.rank = String(i + 1);

            if (room.isCompset) return;

            if (this.ratesService.isAllChannelsMode) {
                if (compsetRoom) {
                    room.diff = (+room.price - +compsetRoom.price) / +compsetRoom.price;
                    room.diff = (room.diff * 100).toFixed(2);
                    room.diff += '%';
                } else {
                    room.diff = '';
                }
            }
        };

        let data = rawEntries
            .map(this.createTableData.bind(this));

        if (this.isCheapestMode) {
            data = data.filter(row => +this.hotelId === +row.hotelId
                    || (row.price && row.price !== PRICE.NA));
        }

        if (compsetRoom) {
            data.push(compsetRoom);
        }

        data.sort(byPriceDescending)
            .filter(room => !room.isCompset && room.price && room.price !== PRICE.NA)
            .reverse()
            .forEach(addRank);

        data.forEach(this.decoratePrice.bind(this));

        return data;
    }

    private cutString(str: string, threshold = 20) {
        return ClipText(str, threshold);
    }

    private createTableData(entry: [number | string, RatesDocumentItemModel]): HotelTableData {
        const { day, hotelId: myHotelId } = this;
        const { hotelNames } = this;
        const { ratesService, providersService } = this;
        const { priceShown } = ratesService.settings;

        const [hotelId, room] = entry;
        const isBasic = room
            ? room.isBasic
            : false;
        const { roomName } = room || { roomName: '-' };

        const isCurrentHotel = +myHotelId! === +hotelId;
        const price = room
            ? ratesService.getPrice(day, hotelId)
            : -1;

        const link = ratesService.getHotelLink(day, hotelId);
        const hotelName = !ratesService.isAllChannelsMode
            ? hotelNames[+hotelId] || String(hotelId)
            : providersService.getProviderLabel(String(hotelId));

        let losRestriction: number | null = null;
        let priceType: string | null = null;

        if (price && price !== PRICE.NA) {
            priceType = room ? room.priceType : null;
            losRestriction = ratesService.isAllChannelsMode
                ? 0
                : ratesService.getHotelLosRestriction(day, +hotelId) || 0;
        }

        let intradayPrice: number | null = 0;
        let analysisPrice: number | null = 0;
        let diff: number | null = 0;

        if (this.isIntraday) {
            intradayPrice = this.ratesService.intradayByHotelId(day, +hotelId, priceShown);
        }

        if (this.isAnalysisMode) {
            analysisPrice = this.ratesAnalysisService.getPrice(day, +hotelId) || -1;

            diff = analysisPrice !== -1 && price && price !== -1
                ? this.ratesAnalysisService.getRoomDifference(day, +hotelId)
                : null;
        }

        const provider = this.ratesService.getRoomProviders(day, +hotelId);

        return {
            hotelId,
            price,
            priceType,
            roomName,
            hotelName,
            rank: '',
            link,
            intradayPrice,
            diff,
            analysisPrice,
            losRestriction,
            provider,
            isCurrentHotel,
            isCompset: false,
            isBasic,
        } as HotelTableData;
    }

    private decoratePrice(room: HotelTableData) {
        const { ratesService, helperService, day } = this;
        const { currency } = ratesService;
        const currencySymbol = helperService.currencySymbol(currency);

        const applyCurrency = (price: number, mainPrice = false) => {
            if (!room.isCompset) {
                const service = mainPrice
                    ? this.ratesService
                    : this.ratesAnalysisService;

                room.hotelId = this.isAllChannelsMode
                    ? room.hotelId
                    : +room.hotelId;

                if (service.isOutOfRange()) {
                    room.roomName = mainPrice ? '-' : room.roomName;
                    return 'Out of Range';
                }

                if (service.isSoldOut(day, room.hotelId)) {
                    room.roomName = mainPrice ? '-' : room.roomName;
                    return 'Sold out';
                }

                if (service.isNA(day, room.hotelId)) {
                    room.roomName = mainPrice ? '-' : room.roomName;
                    return 'N/A';
                }

                if (service.isNoData(day)) {
                    room.roomName = mainPrice ? '-' : room.roomName;
                    return 'No Data';
                }
            } else if (price === PRICE.NA || price === PRICE.SOLD_OUT || price === null) {
                return 'N/A';
            }

            return currencySymbol! + PriceFilter(price, 2);
        };

        room.price = applyCurrency(+room.price, true);

        if (this.isAnalysisMode) {
            room.analysisPrice = applyCurrency(+room.analysisPrice!);

            if (room.diff) {
                room.diff = `${Math.round(+room.diff * 100)}%`;
            }
        }

        if (room.isCompset) return;

        if (!room.intradayPrice) {
            room.intradayPrice = 'Sold out';
        } else {
            room.intradayPrice = currencySymbol! + PriceFilter(room.intradayPrice);
        }
    }

    private getCompsetRoom() {
        const { compsetType, day } = this;

        if (this.isAllChannelsMode && !this.averagePrice) {
            return null;
        }

        const price = !this.ratesService.isAllChannelsMode
            ? this.ratesService.getCompsetPrice(this.day)
            : this.ratesService.getAverageRoomsPrice(this.day);

        if (!price) {
            return null;
        }

        const hotelName = this.isAllChannelsMode
            ? 'All (average)'
            : String(this.$t(`filterSettings.compset_popup_table.${compsetType}`));

        let analysisPrice: number | null = 0;
        let diff = this.isAllChannelsMode ? '' : 0;

        if (this.isAnalysisMode) {
            analysisPrice = this.ratesAnalysisService.getCompsetPrice(day)!;
            diff = (analysisPrice && price)
                ? this.ratesAnalysisService.getCompsetDifference(day)
                : '';
        }

        return {
            hotelId: -1,
            price,
            hotelName,
            roomName: '-',
            link: '',
            rank: '',
            isCompset: true,
            isCurrentHotel: false,
            losRestriction: 0,
            intradayPrice: '',
            diff,
            analysisPrice,
        } as HotelTableData;
    }

    getDummyData() {
        return Array
            .from({ length: 5 })
            .map(() => ({
                hotelId: -1,
                price: '___',
                hotelName: '_______',
                roomName: '________',
                link: '',
                rank: '',
                isCompset: false,
                isCurrentHotel: false,
                intradayPrice: '',
                diff: '__',
                analysisPrice: '_____',
            }));
    }

    onTriggerScan() {
        this.ratesService.triggerScanNew(this.day);
    }

    openPriceHistory() {
        const { data, settings } = this.ratesService;

        if (!data) return;

        this.ratesPriceHistoryCommonService.initRatesData(data, settings);
        // const priceHistoryRoute = `${this.$route.path}/price-history/${this.day}`.replace('//', '/');
        const priceHistoryRoute = {
            name: `${this.$route.name}.price-history-popup`,
            params: {
                ...this.$route.params,
                historyDay: this.$route.params.day,
            },
        };
        this.$router.push(priceHistoryRoute);
    }

    private defineColumnsForAnalysis(
        cols: ColumnData<HotelTableData>[],
        priceCol: ColumnData<HotelTableData>,
    ) {
        cols.splice(0, 1, {
            label: '',
            field: 'compareValue',
            component: (_, item) => ({
                is: 'i',
                class: item.isCompset ? '' : 'icon-service',
            }),
            onHover: this.handleHintHover.bind(this),
            onLeave: this.handleHintLeave.bind(this),
        });

        cols.splice(1, 0, {
            label: 'Diff',
            field: 'diff',
        });

        const analysisHeader = this.ratesAnalysisFiltersService.comparisonValues[0].name;

        cols.splice(2, 0, {
            label: analysisHeader,
            field: 'analysisPrice',
        });

        priceCol.label = String(this.ratesAnalysisFiltersService.mainCompareTitle);
    }

    private handleHintHover(e: MouseEvent, data: HotelTableData) {
        if (data.isCompset) {
            this.handleHintLeave();
            return;
        }

        const focusElement = e.currentTarget!;
        this.$emit('hint-hover', focusElement, data);
    }

    private handleHintLeave() {
        this.$emit('hint-leave');
    }
}
